<template>
  <div class="notinfobox">
    <div class="hadheight"></div>
    <Header />
      <div class="crumbsbox">
          <div class="basecont">
              <router-link to="/">首页</router-link>
              <span>&gt;</span>
              <router-link to="/my/mymessage">个人中心</router-link>
              <span>&gt;</span>
              <router-link to="/my/mynotice">消息通知</router-link>
              <span>&gt;</span>
              <a href="javascript:;" class="on">{{title}}</a>
          </div>
      </div>
      <div class="f_cont base100">
        <div class="basecont">
          <div class="fz_tit basefont30">{{title}}</div>
          <div class="make-text" v-text="text"></div>
          <router-link to="/my/mynotice" class="hm-but f_but">
              <span>返回列表</span>
              <i>
                <img src="../../assets/images/jiantouyo1.png" />
                <img src="../../assets/images/jiantouyo2.png" />
              </i>
            </router-link>
        </div>
      </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  components:{
    Header,
    Footer,
  },
  data(){
    return {
        title: "",
        text: ""
    }
  },
  created() {  //页面创建后
      let data = JSON.parse(sessionStorage.getItem("notinfo"));
      this.title = data.noticeTitle;
      this.text = data.noticeContent;
  },
  mounted(){  //节点创建后
    
  },
  methods: { //方法
    
  },
  watch:{
    title(last,fir){
      document.title = last;
    }
  }
}
</script>
